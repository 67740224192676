export const finxbloxDateEffective = '12/22/23';

// const maxUSDCoinAPY = '12% on first 10k USDC';
// const USDCoinAPY = <div>{maxUSDCoinAPY} <br />9.50% for 10k to 100k USDC <br />5.50% for over 100k USDC</div>;

// const maxTetherAPY = '12% on first 10k USDT';
// const tetherAPY = <div>{maxTetherAPY} <br />9.50% for 10k to 100k USDT <br />5.50% for over 100k USDT</div>;

// const maxDaiAPY = '12% on first 10k DAI';
// const daiAPY = <div>{maxDaiAPY} <br />9.50% for 10k to 100k DAI <br />5.50% for over 100k DAI</div>;

// const maxBinanceUSDAPY = '12% on first 10k BUSD';
// const binanceUSDAPY = <div>{maxBinanceUSDAPY} <br />9.50% for 10k to 100k BUSD <br />5.50% for over 100k BUSD</div>;

export const ratesUS = {};

export const ratesInt = {
    USDC: { base: '4.00%', max: '4.00%' },
    TBILL: { base: '4.70%', max: '4.70%' },
    USDT: { base: '4.50%', max: '4.50%' },
    DAI: { base: '4.50%', max: '4.50%' },
    AXS: { base: '30.00%', max: '30.00%' },
    SLP: { base: '10.00%', max: '10.00%' },
    ETH: { base: '3.50%', max: '3.50%' },
    MATIC: { base: '3.10%', max: '3.10%' },
    ADA: { base: '2.00%', max: '2.00%' },
    APE: { base: '12.00%', max: '12.00%' },
    SHIB: { base: '10.00%', max: '10.00%' },
    FBX: { base: '9.40%', max: '9.40%' },
    BXC: { base: '25.00% (promotional rate)', max: '25.00%' },
    RLB: { base: '3.00%', max: '3.00%' },
    MEME: { base: '3.00%', max: '3.00%' },
    PUMLX: { base: '5.00%', max: '5.00%' },
    SOL: { base: '5.00%', max: '5.00%' },
};
