import React from 'react';
import { FINBLOX_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, finxbloxDateEffective } from './finblox-rates/3-14-24';
import { ratesUS as oldUS, ratesInt as oldInt } from './finblox-rates/12-22-23';

const finbloxDisclaimer = <span>Some Finblox user balances were affected by Three Arrows Capital (3AC) defaulting on numerous debts; see this <a target="_blank" rel="noopener" href="https://help.finblox.com/en/articles/6344319-important-disclaimer">disclaimer</a></span>;
const networkFees = 'network fees + '
const availabilityInfoUS = 'Finblox is no longer available for earning interest in the United States';

export const finbloxWithdrawals = {
    USDC: networkFees + '1 USDC',
    USDT: networkFees + '1 USDT',
    BUSD: networkFees + '1 BUSD',
    DAI: networkFees + '1 DAI',
    XIDR: networkFees + '15,000 XIDR',
    XSGD: networkFees + '1.5 XSGD',
    BTC: networkFees + '0.000042 BTC',
    ETH: networkFees + '0.0006 ETH',
    SOL: networkFees + '0.02 SOL',
    MATIC: networkFees + '1 MATIC',
    AXS: networkFees + '0.05 AXS',
    SLP: networkFees + '200 SLP',
    RON: networkFees + '2.5 RON',
    AVAX: networkFees + '0.05 AVAX',
    NEAR: networkFees + '0.25 NEAR',
    SAND: networkFees + '0.75 SAND',
    BNB: networkFees + '0.005 BNB',
    DOT: networkFees + '0.15 DOT',
    XRP: networkFees + '3 XRP',
    ADA: networkFees + '2 ADA',
    ATOM: networkFees + '0.09 ATOM',
    APE: networkFees + '0.15 APE',
    DOGE: networkFees + '15 DOGE',
    TRX: networkFees + '20 TRX',
    BCH: networkFees + '0.007 BCH',
    SHIB: networkFees + '75,000 SHIB',
    USDD: networkFees + '1 USDD',
};

export const finbloxRowInfoInt = {
    name: 'Finblox',
    withdrawalFees: 'network fees',
    additionalInfo: 'may take up to 48 hours to withdraw',
    icon: '/images/finblox-logo.png',
    order: 5,
    bonus: 'up to $250 in USDC',
    bonusLink: 'finblox-bonus',
    referralSlug: 'finblox-referral',
};

export const finbloxRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Finblox',
    bonusHTML: FINBLOX_BONUS_HTML,
    dateEffectiveUS: finxbloxDateEffective,
    dateEffectiveInt: finxbloxDateEffective,
    availabilityInfoUS: availabilityInfoUS,
    availabilityInfoInt: finbloxDisclaimer,
    key: 'finblox',
};
